<div class="p-grid">
        <div class="p-col">
            <p-card header="ToqWeb" subheader="subtítulo" [style]="{width: '360px'}" styleClass="p-card-shadow">
                <p-header>
                    <img alt="Card" src="../../../assets/layout/images/exemplo.png">
                </p-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                <ng-template pTemplate="footer">
                    <p-button label="Acessar" icon="pi pi-check" (click)="acessarToqWeb()"></p-button>
                </ng-template>
            </p-card>
        </div>
        <div class="p-col">
            <p-card header="PdvWeb" subheader="subtítulo" [style]="{width: '360px'}" styleClass="p-card-shadow">
                <p-header>
                    <img alt="Card" src="../../../assets/layout/images/exemplo.png">
                </p-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                <ng-template pTemplate="footer">
                    <p-button label="Acessar" icon="pi pi-check" (click)="acessarPdv()"></p-button>
                </ng-template>
            </p-card>
        </div>
        <div class="p-col">
            <p-card header="Integra" subheader="subtítulo" [style]="{width: '360px'}" styleClass="p-card-shadow">
                <p-header>
                    <img alt="Card" src="../../../assets/layout/images/exemplo.png">
                </p-header>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                <ng-template pTemplate="footer">
                    <p-button label="Acessar" icon="pi pi-check"></p-button>
                </ng-template>
            </p-card>
        </div>
</div>