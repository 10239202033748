import {Component, OnDestroy, OnInit} from '@angular/core';
import { EMPRESA_STORAGE_KEY, TOKEN_STORAGE_KEY, URL_BANCO_STORAGE_KEY, USUARIO_STORAGE_KEY, EMPRESA_LOGIN_SYSTEM } from 'src/app/controller/staticValues';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

    EMPRESA;

    ngOnInit(): void {
    
    }

    ngOnDestroy(): void {
        
    }

    acessarToqWeb() {
      this.EMPRESA = JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY));
      this.EMPRESA.URI = this.EMPRESA.URI.replace(/['/']/g, '+')
      console.log(JSON.stringify(this.EMPRESA));
      sessionStorage.setItem(EMPRESA_LOGIN_SYSTEM, JSON.stringify(this.EMPRESA));
      window.location.href = `${environment.urlToqWeb}${sessionStorage.getItem(TOKEN_STORAGE_KEY)}` +
        `/${JSON.stringify(sessionStorage.getItem(USUARIO_STORAGE_KEY))}` +
            `/${sessionStorage.getItem(EMPRESA_LOGIN_SYSTEM)}`;
    }

    acessarPdv() {
      window.location.href = `${environment.urlPdv}${sessionStorage.getItem(TOKEN_STORAGE_KEY)}__` +
          `${sessionStorage.getItem(URL_BANCO_STORAGE_KEY)}__${JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY)).ID}`
    }
}