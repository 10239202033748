<div class="topbar">
    <div class="topbar-main">
        <div class="app-name" style="margin-top: 10px;margin-left: 25px;">
            <a href="#">toqWEB</a>
        </div>

        <a href="#" id="user-display" (click)="app.onTopbarMenuButtonClick($event)" style="margin-right: 160px;">
            <span class="username">{{usuario}}</span>
            <span class="userrole">{{empresa}}</span>
            <img alt="main logo" src="assets/layout/images/avatar.png">
        </a>

        <ul id="topbar-menu" class="fadeInDown animated" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
            <li role="menuitem">
                <a (click)="trocarEmpresa()">
                    <i class="fa fa-fw fa-sign-out"></i>
                    <span>Trocar Empresa</span>
                </a>
            </li>
            <li role="menuitem">
                <a href="#" (click)="app.deslogar()">
                    <i class="fa fa-fw fa-sign-out"></i>
                    <span>Sair</span>
                </a>
            </li>

        </ul>

    </div>
</div>
